import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Videos from "../components/videos"
import SEO from "../components/seo"
import { css } from '@emotion/core'

export default ({ data }) => {
  const detail = data.mongodbCloudTechnologies
  let articles = detail.articles;
  articles.sort((a, b) => b.score - a.score);
  articles = articles.filter(a => a.title !== '').filter(a => a.description !== '').filter(a => a.title && a.title.indexOf('on Twitter') === -1);
  articles = removeDuplicates(articles, 'title');
  console.log(articles);
  return (
    <Layout>
      <SEO title={detail.name} description={detail.description} />
      <div>
        <h1>{detail.name}</h1>
        <div dangerouslySetInnerHTML={{ __html: detail.description }} />
        {detail.list.map(({ title, links }, i) => {
          return <div key={i}><h2>{title}</h2>
              {links.map(({ description, name, url}, u) => {
                const divider = description ? '-' : '';
                return <div key={u}><a href={url}>{name}</a> {divider} {description}</div>
              })}
          </div>
        })}
      </div>
      {data.allChannelvideos.nodes.length > 0 ? <h2>Videos related to {detail.name}</h2> : '' }
      <Videos videoData={data.allChannelvideos}></Videos>
      {articles.length > 0 ? <h2>What they say about {detail.name}</h2> : '' }
      {articles.map(({ title, description, image, url }, i) => {
          return <div key={i}><h4><a href={url}>{title}</a></h4>
          <p css={css`display: flex;
                      flex-flow: row nowrap;
                      height: 100%;
                      `}>{image && <a href={url}><img alt="video thumbnail" src={image} css={css`width: 100%; max-height: 90px; max-width: 250px; padding: 0 15px 0 0;`}/></a>} 
          <span>{description} (<a href={url}>visit</a>)
                       </span></p>
          </div>
        })}
    </Layout>
  )
}

function removeDuplicates(myArr, prop) {
  return myArr.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
  });
}

export const query = graphql`
  query($slug: String!, $search: String!) {
    mongodbCloudTechnologies( slug: { eq: $slug } ) {
      name
      description
      articles {
        title
        description
        image
        score
        url
      }
      list {
        title
        links {
          description
          name
          url
        }
      }
    }
    allChannelvideos(filter: {snippet: {title: {regex: $search}}}) {
      nodes {
        snippet {
          description
          title
          thumbnails {
            medium {
              url
              height
              width
            }
          }
        }
        alternative_id {
          videoId
        }
      }
    }
  }
`